<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <span class="text-left text-h4 font-weight-bold">
          About Us
        </span>
        <p class="text-justify pt-4">
          National Society is a online platform to manage societies and flats in India. With the increasing number of
          residential apartments in India, online system adoption has become a necessity to meet every resident's
          expectation. Interactive information exchange and maintaining records online is a great step to go paperless,
          easy to search, interact and fully transparent.
        </p>
        <p class="text-justify">
          For the elderly, it means staying in the comfort of your own home and at the same time interacting with your
          neighbours and flat owners. Keeping a society running smoothly takes a lot of work. National Society
          integrates society maintenance, complaints, discussions, securities etc. online so that you are always
          informed and remain up-to-date about your society and surroundings.
        </p>
        <p class="text-justify">
          Keeping in view that every society cannot afford to buy expensive softwares to manage their societies -
          National Society assures Low Price guarantee and a hassle free support & service throughout the year.
        </p>
        <span class="text-left text-h4 font-weight-bold">
          How it works?
        </span>
        <p class="text-justify pt-4">
          First of all, a Society has to register itself with National Society. A one-time registration fee of INR
          499.00 has to be paid by the Society. Upon successful registration, a unique Id., is provided for the Society.
          All members/ residents of the society will need this Society Id., to register themselves under their Society.
        </p>
        <p class="text-justify">
          National Society has ONE LOGIN for all - which means you can login from anywhere. No matter if you are an
          Administrator of your Society or a member of your Society, the system will automatically take you to your
          homepage.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  export default {
    title: 'About National Society',
    metaInfo: {
      title: 'About National Society'
    },
  }
</script>
<style scoped>
  .about {
    padding-top: 64px !important;
    min-height: 51vh;
  }
</style>